<template>
  <loader v-if="isLoading" message="Loading ...." />
  <s-card class="pa-0 pb-2 clear mb-10" v-else>
    <v-row
      class="customers__table__header table__header pl-10 pr-10 pt-2 ma-0 d-none d-lg-flex"
      no-gutters
    >
      <v-col cols="4">
        <s-text weight="medium" color="gray" size="sm" class="uppercase">
          FQDN
        </s-text>
      </v-col>
      <v-col cols="3" class="pl-1">
        <s-text weight="medium" color="gray" size="sm" class="uppercase">
          MSISDN
        </s-text>
      </v-col>
      <v-col cols="2">
        <s-text weight="medium" color="gray" size="sm" class="uppercase pl-3">
          Telco
        </s-text>
      </v-col>
      <v-col cols="2" class="pl-4">
        <s-text weight="medium" color="gray" size="sm" class="uppercase">
          Date
        </s-text>
      </v-col>
      <v-col cols="1"></v-col>
    </v-row>

    <section class="overflow-hidden">
      <v-row
        class="pl-10 py-3 pr-10 top-thin-border my-0 list_item"
        v-for="(event, index) in logs"
        :key="index"
      >
        <v-col cols="4" class="d-flex align-center ma-0 pr-10 pr-lg-0">
          <s-icon
            name="domain"
            color="grayText"
            class="d-block list_item__icon mr-4"
          />
          <s-text weight="medium" class="pl-8 pl-lg-0 text-truncate">
            {{ event.FQDN }}
          </s-text>
        </v-col>

        <v-col cols="3" class="align-center ma-0 d-none d-lg-flex">
          <s-text weight="medium" class="text-truncate">
            {{ event.MSISDN }}
          </s-text>
        </v-col>

        <v-col cols="2" class="align-center ma-0 d-none d-lg-flex">
          <s-text>
            {{ event.Telco }}
          </s-text>
        </v-col>

        <v-col cols="2" class="d-flex align-center ma-0 list_item__time">
          <s-text class="text-truncate">
            {{ getHumanReadableDate(event.CreatedAt) }}
          </s-text>
        </v-col>
        <v-col cols="1" class="d-flex justify-end ma-0 action_group">
          <s-icon
            name="arrow-circle-right"
            class="mr-4"
            @click="viewEvent(event.id)"
          />
        </v-col>
      </v-row>
    </section>
  </s-card>
</template>
<script>
import { mapGetters } from 'vuex'
import Loader from '@/components/cards/Loader'
import { getHumanReadableDate } from '@/utils/date'
export default {
  name: 'EdgeLogs',
  components: {
    loader: Loader,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      logs: 'edge/currentAppLogs',
    }),
  },
  data() {
    return {
      isLoading: false,
    }
  },
  methods: {
    getHumanReadableDate,
    getCurrentAppLogs() {
      this.isLoading = true
      this.$store.dispatch('edge/getAppLogs', this.id).finally(() => {
        this.isLoading = false
      })
    },
  },
  watch: {
    logs: {
      handler() {
        if (this.logs) return
        this.getCurrentAppLogs()
      },
      immediate: true,
    },
  },
}
</script>
<style lang="scss" scoped></style>
