import moment from 'moment'

export const getHumanReadableDate = (date, format = 'DD/MM/YYYY h:mma') => {
  return `${moment(date).format(format)}`
}

export const getHumanReadableDateAlt = (date) => {
  /** * !important: DO NOT USE */
  return `${moment(date).format('YYYY-MM-DDTHH:mm:ss')}Z`
}

export const formatAllUTCDatesInTextToHumanReadableDates = (text) => {
  // Remove all UTC artifacts
  let formatedText = text.replace(
    /.\d{1,}\s[+-]\d{1,}\sUTC m=[+-]?([1-9]\d*(\.\d*[1-9])?|0\.\d*[1-9]+)|\d+(\.\d*[1-9])?}/gim,
    ''
  )

  // Detect all dates in text
  const dateMatches = formatedText.matchAll(
    /[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]/g
  )

  // Format all detected dates and replace it in text
  for (const match of dateMatches) {
    /**
     * match: match[0]
     * start: match.index
     * end: match.index + match[0].length
     */

    const fmtDate = getHumanReadableDate(match[0], ' ')

    formatedText =
      formatedText.substring(0, match.index) +
      fmtDate +
      formatedText.substring(match.index + match[0].length)
  }

  return formatedText
}
